<template>
  <div class="reservation">
    <div class="header">
      <Label customClass="page-title" color="primary" size="medium" uppercase>
        {{ $t("pages.personalPage.reservations.pageTitle") }}
      </Label>
    </div>
    <div class="content">
      <div class="header--container">
        <div class="car-img--container">
          <img
            class="car-img"
            :src="img"
            :alt="'vehicle-image-' + getReservationVehicle.model"
          />
        </div>
        <PriceModelDetails
          class="mb-6"
          icon-name="info-circle"
          tooltipPosition="left"
          :vehicle="getReservationVehicle"
          :missingActions="missingActionsRetake || missingActionsPreApproval"
          :absolutePrice="!getReservationWithFinance"
          :product-title="getReservationVehicle.model"
          :product-description="getReservationVehicle.commercialDescription"
          :tooltip-description="formatTooltipDescription"
          :tooltip-description-pvp="formatTooltipDescriptionPVP"
          :price-label="getVehicleTotalPriceOrPvpFormatted"
          :modality="formattedMonthlyPrice"
          :finance-taeg="formattedTaeg"
        />
      </div>
      <div v-if="missingActionsRetake || missingActionsPreApproval">
        <div class="separator"></div>
        <div class="action-cards">
          <div class="actions-title mb-8">
            <Label color="primary">
              {{ $t("missingActions.sectionTitle") }}
            </Label>
          </div>
          <div class="action-cards--wrapper">
            <CardWithAction
              v-for="(cardContent, index) in cardsWithAction"
              :key="index"
              @onRedirect="cardContent.onRedirect"
              :title="cardContent.title"
              :description="cardContent.description"
              :warningColor="cardContent.warningColor"
              :warningLabel="cardContent.warningLabel"
            />
          </div>
        </div>
      </div>
      <div class="separator"></div>
      <div class="labelBox vertical">
        <div class="general">
          <Label customClass="with-spacing" color="primary" type="small"
            >Informação Geral</Label
          >
          <DisplayLabelValue
            spaceBetween
            :data="getReservationVehicleGeneralWithLabel"
          />
        </div>
        <div class="finantial vertical" v-if="getReservationWithFinance">
          <Label customClass="with-spacing" color="primary" type="small"
            >Dados Simulação Financeira</Label
          >
          <DisplayLabelValue
            spaceBetween
            :data="getReservationFinanceWithLabels"
          />
        </div>
      </div>
      <div class="separator" v-if="getReservationRetake"></div>
      <div class="labelBox-retake vertical" v-if="getReservationRetake">
        <Label customClass="with-spacing" color="primary" type="small"
          >Informação da Retoma</Label
        >
        <div />
        <DisplayLabelValue :data="getReservationRetakeLeftWithLabels" />
        <DisplayLabelValue :data="getReservationRetakeRightWithLabels" />
      </div>
      <div class="separator" v-if="getReservationRetake"></div>
      <div
        class="flex flex-col retake-value vertical"
        v-if="getReservationRetake"
      >
        <Label customClass="with-spacing" color="primary" type="large"
          >Valor da Retoma
        </Label>
        <Label customClass="price" color="secondary"
          >{{ getRetakeValuationLabel }}
        </Label>
      </div>
      <div class="separator"></div>
      <div class="labelBox vertical">
        <div class="personal">
          <Label customClass="with-spacing" color="primary" type="small"
            >Informação Pessoal</Label
          >
          <DisplayLabelValue :data="getReservationUserWithLabel" />
        </div>
        <div class="receipt">
          <Label customClas="with-spacing" color="primary" type="small"
            >Dados de Faturação</Label
          >
          <DisplayLabelValue :data="getReservationReceiptWithLabels" />
        </div>
      </div>
      <div class="separator"></div>
      <div class="dealer vertical" v-if="getDealersArray.length">
        <div class="dealer-data">
          <Label color="primary" type="small"
            >Concessionário Intermediário</Label
          >
          <DisplayLabelValue
            :data="
              $store.getters.getReservationDealerWithLabels(
                getCurrentReservation.dealerCode
              )
            "
          />
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="close-btn">
        <Button @onClick="goToReservations" color="primary" label="Fechar" />
      </div>
      <div class="close-btn">
        <Button
          v-if="getReservationWithFinance"
          @onClick="goToPreApproval"
          color="primary"
          label="Pré-aprovação"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { Label, Button } from "../../../atoms";
import { DisplayLabelValue, PriceModelDetails } from "../../../molecules";
import {
  generateTooltipDescription,
  getReserveTooltipPVP
} from "../../../../utils/tooltip";
import { CardWithAction } from "../../../organisms";

import { PAGES } from "@/enums/pages";

export default {
  name: "ReservationDetails",
  components: {
    Label,
    DisplayLabelValue,
    PriceModelDetails,
    Button,
    CardWithAction
  },
  methods: {
    goToReservations() {
      this.$router.push({
        name: PAGES.RESERVATIONS
      });
    },
    goToPreApproval() {
      this.$router.push({
        name: PAGES.PRE_APPROVAL,
        params: { id: this.$route.params.id }
      });
    },
    redirectQ2Form() {
      this.$router.push({
        name: PAGES.RETAKES
      });
    }
  },
  computed: {
    ...mapGetters([
      "getCurrentReservation",
      "getReservationReceipt",
      "getReservationVehicle",
      "getReservationUserWithLabel",
      "getReservationReceiptWithLabels",
      "getReservationFinanceWithLabels",
      "getFormattedReturnValue",
      "getReservationVehicleGeneralWithLabel",
      "getReservationRetakeLeftWithLabels",
      "getReservationRetakeRightWithLabels",
      "getReservationRetake",
      "getReservationWithFinance",
      "getVehicleTotalPriceOrPvpFormatted",
      "getPreApprovalIsSubmited",
      "getReservationCurrentRetakeSubmitted",
      "getReservations",
      "getDealersArray"
    ]),
    formatTooltipDescriptionPVP() {
      return getReserveTooltipPVP({
        ...this.getCurrentReservation.vehicle.tooltipDescription,
        commercialDescription: this.getCurrentReservation.vehicle
          .commercialDescription,
        model: this.getCurrentReservation.vehicle.model,
        pricePvp: this.getCurrentReservation.vehicle.pricePvp
      });
    },
    missingActionsRetake() {
      return (
        this.getReservationRetake && !this.getReservationCurrentRetakeSubmitted
      );
    },
    missingActionsPreApproval() {
      return this.getReservationWithFinance && !this.getPreApprovalIsSubmited;
    },
    cardsWithAction() {
      const cardActionContents = [
        {
          onRedirect: this.redirectQ2Form,
          title: this.$t("missingActions.retake.title"),
          description: this.$t("missingActions.retake.description"),
          warningLabel: this.$t("missingActions.retake.warningLabel"),
          warningColor: this.$t("missingActions.retake.warningColor")
        },
        {
          onRedirect: this.goToPreApproval,
          title: this.$t("missingActions.preApproval.title"),
          description: this.$t("missingActions.preApproval.description"),
          warningLabel: this.$t("missingActions.preApproval.warningLabel"),
          warningColor: this.$t("missingActions.preApproval.warningColor")
        }
      ];

      if (!this.missingActionsRetake) cardActionContents.shift();
      if (!this.missingActionsPreApproval) cardActionContents.pop();

      return cardActionContents;
    },
    img() {
      return (
        this.getReservationVehicle?.imageUrl ||
        require("../../../../assets/images/placeholder.jpg")
      );
    },
    formattedTaeg() {
      return (
        "TAEG: " + Number(this.getReservationVehicle?.taeg).toFixed(1) + " %"
      );
    },
    formattedMonthlyPrice() {
      return Number(this.getReservationVehicle?.monthlyPrice) + " €";
    },
    formatTooltipDescription() {
      if (!this.getReservationVehicle?.tooltipDescription) return "";
      return generateTooltipDescription({
        ...this.getReservationVehicle?.tooltipDescription,
        pricePvp: this.getReservationVehicle?.pricePvp,
        commercialDescription: this.getReservationVehicle?.commercialDescription
      });
    },
    getRetakeValuationLabel() {
      return this.getFormattedReturnValue === 0 ||
        this.getFormattedReturnValue === "0"
        ? this.$t("pages.retake.emptyValue")
        : this.getFormattedReturnValue;
    }
  },
  async mounted() {
    if (!this.getDealersArray.length)
      await this.$store.dispatch("storeDealers");
    await this.$store.dispatch("storeReservations");
    //await this.$store.dispatch("storeDropdowns");
    this.$store.commit("setCurrentReservation", Number(this.$route.params.id));
  }
};
</script>

<style scoped>
:deep(.state-label) {
  font-size: 13px;
  line-height: 20px;
  @apply font-normal;
}

.action-cards--wrapper :deep(.card-content--title) {
  @apply font-head;
  font-size: 18px;
  line-height: 24px;
  @apply font-normal;
}

.action-cards--wrapper :deep(.card-content--description) {
  @apply font-head;
  font-size: 16px;
  line-height: 20px;
  @apply font-normal;
}

.action-cards--wrapper {
  display: flex;
  @apply flex-col;
  @apply sm:flex-row;
}

.actions-title :deep(.label) {
  @apply font-head;
  font-size: 16px;
  @apply font-normal;
  line-height: 24px;
}

.action-cards {
  display: flex;
  flex-direction: column;
}

:deep(.missing-action-card) {
  @apply mx-4;
  @apply sm:w-full;
}

.header {
  border-bottom: 2px solid #e4ecf0;
  @apply pb-5;
}

.content {
  @apply mt-5;
  background-color: #f5f7f9;
  @apply p-10;
}

.page-title {
  font-size: 24px;
}

.separator {
  @apply pt-6;
  @apply mb-6;
  border-bottom: 2px solid #e4ecf0;
}

.footer {
  @apply mt-10;
  @apply flex;
  @apply justify-end;
}

.footer .button {
  width: 182px;
  height: 50px;
}

.close-btn {
  @apply ml-6;
}

.labelBox {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr 1fr;
}

@media screen and (max-width: 770px) {
  .labelBox {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

@media screen and (max-width: 770px) {
  .labelBox-retake {
    grid-template-columns: 1fr;
    gap: 0;
  }
}

.retake {
  grid-row-gap: 0;
}

.header--container {
  display: flex;
  align-items: center;
}

.car-img {
  object-fit: contain;
  object-position: center;
  width: 170px;
  height: 110px;
}

.car-img--container {
  display: flex;
  margin-right: 1rem;
}

:deep(.finance-model-modality) {
  font-weight: 700;
  font-size: 16px;
}

:deep(.finance-model-taeg) {
  font-weight: 700;
  font-size: 16px;
}

:deep(.finance-model-price) {
  font-size: 20px !important;
  line-height: 20px !important;
  font-weight: 700 !important;
}

:deep(.finance-model-modality) {
  font-size: 20px !important;
  line-height: 20px !important;
  font-weight: 700 !important;
}

:deep(.finance-model-taeg) {
  font-size: 20px !important;
  line-height: 20px !important;
  font-weight: 700 !important;
}

@media (max-width: 1440px) {
  :deep(.finance-model-price.finance--container-title) {
    font-size: 20px !important;
    line-height: 20px !important;
    font-weight: 700 !important;
  }

  :deep(.finance-model-modality.finance--container-subtitle) {
    font-size: 20px !important;
    line-height: 20px !important;
    font-weight: 700 !important;
  }

  :deep(.finance-model-taeg.finance--container-subtitle) {
    font-size: 20px !important;
    line-height: 20px !important;
    font-weight: 700 !important;
  }
}

:deep(.finance--wrapper) {
  margin-bottom: 0px;
}

@media (max-width: 768px) {
  :deep(.header--container) {
    flex-direction: column;
  }

  :deep(.car-img--container) {
    margin: 0 0 20px 0;
    width: 100%;
  }

  :deep(.car-img--container) img {
    margin: 0px;
    width: 100%;
  }

  .content {
    padding: 16px;
  }

  .footer {
    flex-direction: column;
    padding: 16px;
    margin-top: 0;
  }

  .footer .close-btn {
    width: 100%;
    margin: 0;
  }

  .footer .close-btn .button {
    width: 100%;
    margin: 4px 0;
  }
}
</style>
